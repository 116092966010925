import * as React from 'react';
import { styled, useTheme, createTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import PeopleIcon from '@mui/icons-material/People';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ListAltIcon from '@mui/icons-material/ListAlt';
// import CastConnectedIcon from '@mui/icons-material/CastConnected';
import PaidIcon from '@mui/icons-material/Paid';
import '../common-navbar/navbar.css';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import Tooltip from '@mui/material/Tooltip';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Collapse from '@mui/material/Collapse';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import AddCardIcon from '@mui/icons-material/AddCard';
import Logo from '../../../assets/loginlogo.png';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LanguageIcon from '@mui/icons-material/Language';
import { selectClasses } from '@mui/material';
import { useSelector } from 'react-redux';
const drawerWidth = 240;



const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  position:'sticky',
  top:0,
  zIndex:10,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',


    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
      background: 'black',
      color: 'white',
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
      background: 'black',
      color: 'white',
    }),
  }),
);

export default function Navbar(props) {
  const {accessRights} = useSelector(store => store.login)
  const navigate = useNavigate()
  const [selectedSetup,setSelectedSetup] = React.useState(false)
  let selInd = sessionStorage.getItem("selectedIndex") == null ? 1 : sessionStorage.getItem("selectedIndex")
  let childInd = sessionStorage.getItem("selectedChilIndex") == null ? 0 : sessionStorage.getItem("selectedChilIndex")

  const [selectedIndex, setSelectedIndex] = React.useState(parseInt(selInd));
  const [childIndexSetup, setChildIndexSetup] = React.useState(parseInt(childInd))
  const [openSetupToggle, setOpenSetupToggle] = React.useState(false);
  const [adminToggle, setAdminToggle] = React.useState(false)
  const [systemToggle, setSystemToggle] = React.useState(false)
  // const theme = useTheme();
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1450,
      },
    },
  });

  const xlResolutionIdentifier = useMediaQuery(theme.breakpoints.down('xl'));
  const lgResolutionIdentifier = useMediaQuery(theme.breakpoints.down('lg'));
  const mdResolutionIdentifier = useMediaQuery(theme.breakpoints.down('md'));
  const smResolutionIdentifier = useMediaQuery(theme.breakpoints.down('sm'));
  const xsResolutionIdentifier = useMediaQuery(theme.breakpoints.down('xs'));
  const [largeRes, setLargeRes] = React.useState(false)
  const [smallRes, setSmallRes] = React.useState(false)
  React.useEffect(() => {
    if (xlResolutionIdentifier || lgResolutionIdentifier) {
      setLargeRes(true)
      setSmallRes(false)
    }
    else {
      setLargeRes(false)
      setSmallRes(true)
    }
  }, [xlResolutionIdentifier, lgResolutionIdentifier, mdResolutionIdentifier, smResolutionIdentifier, xsResolutionIdentifier])

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    props.drawerClose()
  };

  

  return (
    <>
      {largeRes && <SwipeableDrawer
        className='small-drawer'
        anchor={'left'}
        open={props.open}
        onClose={toggleDrawer('left', false)}
        onOpen={toggleDrawer('left', true)}
      >
        <DrawerHeader style={{backgroundColor:"#1976d2"}}>
          <img src={Logo} style={{ position: 'absolute', left: '7px', width: '155px' }} />
          <IconButton onClick={props.drawerClose} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <FormatIndentDecreaseIcon sx={{ color: '#fff', size: 20 }} />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              id="nav-1"
              selected={selectedIndex === 1}
              onClick={() => {
                sessionStorage.setItem('selectedIndex', 1)
                props.navigateOne()
                setSelectedIndex(1)
                setChildIndexSetup(0)
                setOpenSetupToggle(false);
                setAdminToggle(false)
                setSystemToggle(false)
                props.drawerClose()
              }
              }
              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,

              }
              }
            >
              <Tooltip title={window.gt('Dashboard')} placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <DashboardIcon className={props.open ? 'no-apply-btn' : 'apply-btn'} />
              </ListItemIcon></Tooltip>
              <ListItemText primary={window.gt('Dashboard')} sx={{ opacity: props.open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              id="nav-3"
              selected={selectedIndex === 3}
              onClick={() => {
                sessionStorage.setItem('selectedIndex', 3)
                setSelectedIndex(3)
                props.navigateThree()
                setChildIndexSetup(0)
                setOpenSetupToggle(false);
                setAdminToggle(false)
                setSystemToggle(false)
                props.drawerClose()
              }}
              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,
              }
              }
            >
              <Tooltip title={window.gt('Profile Status')} placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <AssessmentIcon className={props.open ? 'no-apply-btn' : 'apply-btn'} />
              </ListItemIcon></Tooltip>
              <ListItemText primary={window.gt('Profile Status')} sx={{ opacity: props.open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              id="nav-4"
              selected={selectedIndex === 4}
              onClick={() => {
                sessionStorage.setItem('selectedIndex', 4)
                setSelectedIndex(4)
                props.navigateInvoiceCredits()
                setOpenSetupToggle(false);
                setAdminToggle(false)
                setSystemToggle(false)
                setChildIndexSetup(0)
                props.drawerClose()
              }}
              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,

              }
              }
            >
              <Tooltip title={window.gt('Invoice and Credits')} placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <RequestQuoteIcon className={props.open ? 'no-apply-btn' : 'apply-btn'} />

              </ListItemIcon></Tooltip>
              <ListItemText primary={window.gt('Invoice and Credits')} className={props.open ? 'break-space' : ''} sx={{ opacity: props.open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              id="nav-6"
              selected={selectedIndex === 6}
              onClick={() => {
                sessionStorage.setItem('selectedIndex', 6)
                setSelectedIndex(6)
                props.setupStructureTrigger()
                setOpenSetupToggle(true)
              }}

              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,

              }
              }
            >
              <Tooltip title={window.gt('Setup')} placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <SettingsIcon className={props.open ? 'no-apply-btn' : 'apply-btn'} />
              </ListItemIcon></Tooltip>
              <ListItemText primary={window.gt('Setup')} sx={{ opacity: props.open ? 1 : 0 }} />
              {props.open && <span>{openSetupToggle ? <ExpandLess /> : <ExpandMore />}</span>}
            </ListItemButton>
            {props.open && <Collapse in={openSetupToggle} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 8.7 }} id="nav-ch-1" className='child-selected' onClick={() => {
                  setChildIndexSetup(1)
                  sessionStorage.setItem('selectedIndex', 6)
                  sessionStorage.setItem('selectedChilIndex', 1)
                  setSelectedIndex(6)
                  props.setupStructureTrigger()
                  props.triggerStructure()
                  props.drawerClose()
                }} selected={childIndexSetup === 1}>
                  <ListItemText primary={window.gt('Client')} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 8.7 }} id="nav-ch-2" className='child-selected' onClick={() => {
                  setChildIndexSetup(2)
                  sessionStorage.setItem('selectedIndex', 6)
                  sessionStorage.setItem('selectedChilIndex', 2)
                  setSelectedIndex(6)
                  props.setupClientTrigger()
                  props.drawerClose()
                }} selected={childIndexSetup === 2}>
                  <ListItemText primary={window.gt('Send Questionnaire')} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 8.7 }} id="nav-ch-3" className='child-selected' onClick={() => {
                  setChildIndexSetup(3)
                  sessionStorage.setItem('selectedIndex', 6)
                  sessionStorage.setItem('selectedChilIndex', 3)
                  setSelectedIndex(6)
                  props.setupContactTrigger()
                  props.drawerClose()
                }} selected={childIndexSetup === 3}>
                  <ListItemText primary={window.gt('Email Template')} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 8.7 }} id="nav-ch-4" className='child-selected' onClick={() => {
                  setChildIndexSetup(4)
                  sessionStorage.setItem('selectedIndex', 6)
                  sessionStorage.setItem('selectedChilIndex', 4)
                  setSelectedIndex(6)
                  props.setupEmailTrigger()
                  props.drawerClose()
                }} selected={childIndexSetup === 4}>
                  <ListItemText primary={window.gt('Teams')} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 8.7 }} id="nav-ch-5" className='child-selected' onClick={() => {
                  setChildIndexSetup(5)
                  sessionStorage.setItem('selectedIndex', 6)
                  sessionStorage.setItem('selectedChilIndex', 5)
                  setSelectedIndex(6)
                  props.massDeletenavigate()
                  props.drawerClose()
                }} selected={childIndexSetup === 5}>
                  <ListItemText primary={window.gt('Mass Delete')} />
                </ListItemButton>
              </List>
            </Collapse>}
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              id="nav-7"
              selected={selectedIndex === 7}
              onClick={() => {
                sessionStorage.setItem('selectedIndex', 7)
                setSelectedIndex(7)
                props.navigatePreference()
                setOpenSetupToggle(false);
                setAdminToggle(false)
                setSystemToggle(false)
                setChildIndexSetup(0)
                props.drawerClose()
              }
              }
              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,
              }
              }
            >
              <Tooltip title={window.gt('Preference')} placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <AccountCircleIcon className={props.open ? 'no-apply-btn' : 'apply-btn'} />
              </ListItemIcon>
              </Tooltip>
              <ListItemText primary={window.gt('Preference')} sx={{ opacity: props.open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              id="nav-8"
              selected={selectedIndex === 8}
              onClick={() => {
                sessionStorage.setItem('selectedIndex', 8)
                setSelectedIndex(8)
                props.resourcenavigate()
                setOpenSetupToggle(false);
                setAdminToggle(false)
                setSystemToggle(false)
                setChildIndexSetup(0)
                props.drawerClose()
              }
              }
              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,

              }
              }
            >
              <Tooltip title={window.gt('Resource')} placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <MenuBookIcon className={props.open ? 'no-apply-btn' : 'apply-btn'} />
              </ListItemIcon>
              </Tooltip>
              <ListItemText primary={window.gt('Resource')} sx={{ opacity: props.open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              id="nav-5"
              selected={selectedIndex === 5}
              onClick={() => {
                sessionStorage.setItem('selectedIndex', 5)
                setSelectedIndex(5)
                props.navigateReport()
                setOpenSetupToggle(false);
                setAdminToggle(false)
                setSystemToggle(false)
                setChildIndexSetup(0)
                props.drawerClose()
              }}
              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,

              }
              }
            >
              <Tooltip title={window.gt('Reports')} placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <ListAltIcon className={props.open ? 'no-apply-btn' : 'apply-btn'} />
              </ListItemIcon></Tooltip>
              <ListItemText primary={window.gt('Reports')} sx={{ opacity: props.open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          {(accessRights === 'ADMIN' || accessRights === 'SYSTEM') && <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              id="nav-9"
              selected={selectedIndex === 9}
              onClick={() => {
                sessionStorage.setItem('selectedIndex', 9)
                setSelectedIndex(9)
                props.setupStructureTrigger()
                if (adminToggle) {
                  setAdminToggle(false)
                }
                else {
                  setAdminToggle(true)
                }
              }}

              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,

              }
              }
            >
              <Tooltip title={window.gt('Administration')} placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <SettingsIcon className={props.open ? 'no-apply-btn' : 'apply-btn'} />
              </ListItemIcon></Tooltip>
              <ListItemText primary={window.gt('Administration')} sx={{ opacity: props.open ? 1 : 0 }} />
              {props.open && <span>{adminToggle ? <ExpandLess /> : <ExpandMore />}</span>}
            </ListItemButton>
            {props.open && <Collapse in={adminToggle} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 8.7 }} id="nav-ch-1" className='child-selected' onClick={() => {
                  setChildIndexSetup(8)
                  sessionStorage.setItem('selectedIndex', 9)
                  setSelectedIndex(9)
                  props.adminCompanyTrigger()
                  props.drawerClose()

                }} selected={childIndexSetup === 8}>
                  <ListItemText primary={window.gt('Company')} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 8.7 }} id="nav-ch-2" className='child-selected' onClick={() => {
                  
                  setChildIndexSetup(9)
                  sessionStorage.setItem('selectedIndex', 9)
                  setSelectedIndex(9)
                  props.navigateAdminInvoices()
                  props.drawerClose()
                }} selected={childIndexSetup === 9}>
                  <ListItemText primary={window.gt('Invoices')} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 8.7 }} id="nav-ch-3" className='child-selected' onClick={() => {
                  setChildIndexSetup(10)
                  sessionStorage.setItem('selectedIndex', 9)
                  setSelectedIndex(9)
                  props.drawerClose()
                  props.navigateAdminMaterails()
                }} selected={childIndexSetup === 10}>
                  <ListItemText primary={window.gt('Materials')} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 8.7 }} id="nav-ch-4" className='child-selected' onClick={() => {
                  setChildIndexSetup(11)
                  sessionStorage.setItem('selectedIndex', 9)
                  setSelectedIndex(9)
                  props.drawerClose()
                  setAdminToggle(false)
                  setSystemToggle(false)
                  props.navigateAdminResource()
                }} selected={childIndexSetup === 11}>
                  <ListItemText primary={window.gt('Resource')} />
                </ListItemButton>
              </List>
            </Collapse>}
          </ListItem>}

          {(accessRights === 'SYSTEM') && <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              id="nav-10"
              selected={selectedIndex === 10}
              onClick={() => {
                sessionStorage.setItem('selectedIndex', 10)
                setSelectedIndex(10)
                props.setupStructureTrigger()
                if (systemToggle) {
                  setSystemToggle(false)
                }
                else {
                  setSystemToggle(true)
                }
              }}

              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,

              }
              }
            >
              <Tooltip title={window.gt('Systems')} placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <LanguageIcon className={props.open ? 'no-apply-btn' : 'apply-btn'} />
              </ListItemIcon></Tooltip>
              <ListItemText primary={window.gt('Systems')} sx={{ opacity: props.open ? 1 : 0 }} />
              {props.open && <span>{systemToggle ? <ExpandLess /> : <ExpandMore />}</span>}
            </ListItemButton>
            {props.open && <Collapse in={systemToggle} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 8.7 }} id="nav-ch-1" className='child-selected' onClick={() => {
                  setChildIndexSetup(15)
                  sessionStorage.setItem('selectedIndex', 10)
                  setSelectedIndex(10)
                  props.navigateAdminSysLoookups()
                }} selected={childIndexSetup === 15}>
                  <ListItemText primary={window.gt('Lookups')} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 8.7 }} id="nav-ch-2" className='child-selected' onClick={() => {
                  setChildIndexSetup(16)
                  sessionStorage.setItem('selectedIndex', 10)
                  setSelectedIndex(10)
                  props.navigateLanguages()
                }} selected={childIndexSetup === 16}>
                  <ListItemText primary={window.gt('Languages')} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 8.7 }} id="nav-ch-2" className='child-selected' onClick={() => {
                  setChildIndexSetup(16)
                  sessionStorage.setItem('selectedIndex', 11)
                  setSelectedIndex(10)
                  props.navigateLanguages()
                }} selected={childIndexSetup === 17}>
                  <ListItemText primary={window.gt('Refresh Cache')} />
                </ListItemButton>
              </List>
            </Collapse>}
          </ListItem>}
        </List>
      </SwipeableDrawer>}
      {smallRes && <Drawer variant="permanent" open={props.open} className="sidebar_bg">
        <DrawerHeader style={{backgroundColor:"#1976d2"}}>
          <img src={Logo} style={{ position: 'absolute', left: '7px', width: '155px' }} />
          {props.open && <IconButton onClick={props.drawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <FormatIndentDecreaseIcon sx={{ color: '#fff' }} />}
          </IconButton>}
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding sx={{ display: 'block', }}>
            <ListItemButton
              id="nav-1"
              selected={selectedIndex === 1}
              onClick={() => {
                sessionStorage.setItem('selectedIndex', 1)
                props.navigateOne()
                setSelectedIndex(1)
                setChildIndexSetup(0)
                setOpenSetupToggle(false);
                setAdminToggle(false)
                setSystemToggle(false)
              }
              }
              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,

              }
              }
            >
              <Tooltip title={window.gt('Dashboard')} placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <DashboardIcon className={props.open ? 'no-apply-btn' : 'apply-btn'} />
              </ListItemIcon></Tooltip>
              <ListItemText primary={window.gt('Dashboard')} sx={{ opacity: props.open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              id="nav-3"
              selected={selectedIndex === 3}
              onClick={() => {
                sessionStorage.setItem('selectedIndex', 3)
                setSelectedIndex(3)
                props.navigateThree()
                setChildIndexSetup(0)
                setOpenSetupToggle(false);
                setAdminToggle(false)
                setSystemToggle(false)
              }}
              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,

              }
              }
            >
              <Tooltip title={window.gt('Profile Status')} placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <AssessmentIcon className={props.open ? 'no-apply-btn' : 'apply-btn'} />
              </ListItemIcon></Tooltip>
              <ListItemText primary={window.gt('Profile Status')} sx={{ opacity: props.open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              id="nav-4"
              selected={selectedIndex === 4}
              onClick={() => {
                sessionStorage.setItem('selectedIndex', 4)
                setSelectedIndex(4)
                props.navigateInvoiceCredits()
                setOpenSetupToggle(false);
                setAdminToggle(false)
                setSystemToggle(false)
                setChildIndexSetup(0)
              }}
              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,

              }
              }
            >
              <Tooltip title={window.gt('Invoice and Credits')} placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <RequestQuoteIcon className={props.open ? 'no-apply-btn' : 'apply-btn'} />
              </ListItemIcon></Tooltip>
              <ListItemText primary={window.gt('Invoice and Credits')} className={props.open ? 'break-space' : ''} sx={{ opacity: props.open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              id="nav-6"
              selected={selectedIndex === 6}
              onClick={() => {
                sessionStorage.setItem('selectedIndex', 6)
                setSelectedIndex(6)
                props.setupStructureTrigger()
                setOpenSetupToggle(!openSetupToggle)
                setAdminToggle(false)
                setSystemToggle(false)
              }}

              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,

              }
              }
            >
              <Tooltip title={window.gt('Setup')} placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <SettingsIcon className={props.open ? 'no-apply-btn' : 'apply-btn'} />
              </ListItemIcon></Tooltip>
              <ListItemText primary={window.gt('Setup')} sx={{ opacity: props.open ? 1 : 0 }} />
              {props.open && <span style={{position: 'relative', top: '4px'}}>{openSetupToggle ? <ExpandLess /> : <ExpandMore />}</span>}
            </ListItemButton>
            {props.open && <Collapse in={openSetupToggle} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 8.7 }} id="nav-ch-1" className='child-selected' onClick={() => {
                  setChildIndexSetup(1)
                  sessionStorage.setItem('selectedIndex', 6)
                  sessionStorage.setItem('selectedChilIndex', 1)
                  setSelectedIndex(6)
                  setOpenSetupToggle(true)
                  props.setupStructureTrigger()
                  props.triggerStructure()
                }} selected={childIndexSetup === 1}>
                  <ListItemText primary={window.gt('Client')} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 8.7 }} id="nav-ch-2" className='child-selected' onClick={() => {
                  setChildIndexSetup(2)
                  sessionStorage.setItem('selectedIndex', 6)
                  sessionStorage.setItem('selectedChilIndex', 2)
                  setSelectedIndex(6)
                  setOpenSetupToggle(true)
                  props.setupClientTrigger()
                }} selected={childIndexSetup === 2}>
                  <ListItemText primary={window.gt('Send Questionnaire')} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 8.7 }} id="nav-ch-3" className='child-selected' onClick={() => {
                  setChildIndexSetup(3)
                  sessionStorage.setItem('selectedIndex', 6)
                  sessionStorage.setItem('selectedChilIndex', 3)
                  setSelectedIndex(6)
                  setOpenSetupToggle(true)
                  props.setupContactTrigger()
                }} selected={childIndexSetup === 3}>
                  <ListItemText primary={window.gt('Email Template')} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 8.7 }} id="nav-ch-4" className='child-selected' onClick={() => {
                  setChildIndexSetup(4)
                  sessionStorage.setItem('selectedIndex', 6)
                  sessionStorage.setItem('selectedChilIndex', 4)
                  setSelectedIndex(6)
                  setOpenSetupToggle(true)
                  props.setupEmailTrigger()
                }} selected={childIndexSetup === 4}>
                  <ListItemText primary={window.gt('Teams')} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 8.7 }} id="nav-ch-5" className='child-selected' onClick={() => {
                  setChildIndexSetup(5)
                  sessionStorage.setItem('selectedIndex', 6)
                  sessionStorage.setItem('selectedChilIndex', 5)
                  setSelectedIndex(6)
                  setOpenSetupToggle(true)
                  props.massDeletenavigate()

                }} selected={childIndexSetup === 5}>
                  <ListItemText primary={window.gt('Mass Delete')} />
                </ListItemButton>
              </List>
            </Collapse>}
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              id="nav-7"
              selected={selectedIndex === 7}
              onClick={() => {
                sessionStorage.setItem('selectedIndex', 7)
                setSelectedIndex(7)
                props.navigatePreference()
                setOpenSetupToggle(false);
                setAdminToggle(false)
                setSystemToggle(false)
                setChildIndexSetup(0)
              }
              }
              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,
              }
              }
            >
              <Tooltip title={window.gt('Preference')} placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <AccountCircleIcon className={props.open ? 'no-apply-btn' : 'apply-btn'} />
              </ListItemIcon>
              </Tooltip>
              <ListItemText primary={window.gt('Preference')} sx={{ opacity: props.open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              id="nav-8"
              selected={selectedIndex === 8}
              onClick={() => {
                sessionStorage.setItem('selectedIndex', 8)
                setSelectedIndex(8)
                props.resourcenavigate()
                setOpenSetupToggle(false);
                setAdminToggle(false)
                setSystemToggle(false)
                setChildIndexSetup(0)
              }
              }
              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,

              }
              }
            >
              <Tooltip title={window.gt('Resource')} placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <MenuBookIcon className={props.open ? 'no-apply-btn' : 'apply-btn'} />
              </ListItemIcon>
              </Tooltip>
              <ListItemText primary={window.gt('Resource')} sx={{ opacity: props.open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              id="nav-5"
              selected={selectedIndex === 5}
              onClick={() => {
                sessionStorage.setItem('selectedIndex', 5)
                setSelectedIndex(5)
                props.navigateReport()
                setOpenSetupToggle(false);
                setAdminToggle(false)
                setSystemToggle(false)
                setChildIndexSetup(0)
              }}
              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,

              }
              }
            >
              <Tooltip title={window.gt('Reports')} placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <ListAltIcon className={props.open ? 'no-apply-btn' : 'apply-btn'} />
              </ListItemIcon></Tooltip>
              <ListItemText primary={window.gt('Reports')} sx={{ opacity: props.open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          {(accessRights === 'ADMIN' || accessRights === 'SYSTEM') && <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              id="nav-9"
              selected={selectedIndex === 9}
              onClick={() => {
                sessionStorage.setItem('selectedIndex', 9)
                setSelectedIndex(9)
                props.setupStructureTrigger()
                setAdminToggle(!adminToggle)
                setOpenSetupToggle(false)
                setSystemToggle(false)
              }}

              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,

              }
              }
            >
              <Tooltip title={window.gt('Administration')} placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <ManageAccountsIcon  className={props.open ? 'no-apply-btn' : 'apply-btn'} />
              
              </ListItemIcon></Tooltip>
              <ListItemText primary={window.gt('Administration')} sx={{ opacity: props.open ? 1 : 0 }} />
              {props.open && <span style={{position: 'relative', top: '2px'}}>{adminToggle ? <ExpandLess /> : <ExpandMore />}</span>}
            </ListItemButton>
            {props.open && <Collapse in={adminToggle} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 8.7 }} id="nav-ch-1" className='child-selected' onClick={() => {
                  setChildIndexSetup(8)
                  sessionStorage.setItem('selectedIndex', 9)
                  sessionStorage.setItem('selectedChilIndex', 8)
                  setSelectedIndex(9)
                  setAdminToggle(true)
                  props.setupStructureTrigger()
                  props.adminCompanyTrigger()
                }} selected={childIndexSetup === 8}>
                  <ListItemText primary={window.gt('Company')} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 8.7 }} id="nav-ch-2" className='child-selected' onClick={() => {
                  setChildIndexSetup(9)
                  sessionStorage.setItem('selectedIndex', 9)
                  sessionStorage.setItem('selectedChilIndex', 9)
                  setSelectedIndex(9)
                  setAdminToggle(true)
                  props.setupStructureTrigger()
                  props.navigateAdminInvoices()
                }} selected={childIndexSetup === 9}>
                  <ListItemText primary={window.gt('Invoices')} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 8.7 }} id="nav-ch-3" className='child-selected' onClick={() => {
                  setChildIndexSetup(10)
                  sessionStorage.setItem('selectedIndex', 9)
                  sessionStorage.setItem('selectedChilIndex', 10)
                  setSelectedIndex(9)
                  setAdminToggle(true)
                  props.setupStructureTrigger()
                  props.navigateAdminMaterails()
                }} selected={childIndexSetup === 10}>
                  <ListItemText primary={window.gt('Materials')} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 8.7 }} id="nav-ch-4" className='child-selected' onClick={() => {
                  setChildIndexSetup(11)
                  sessionStorage.setItem('selectedIndex', 9)
                  sessionStorage.setItem('selectedChilIndex', 11)
                  setSelectedIndex(9)
                  setAdminToggle(true)
                  props.setupStructureTrigger()
                  props.navigateAdminResource()
                }} selected={childIndexSetup === 11}>
                  <ListItemText primary={window.gt('Resource')} />
                </ListItemButton>
              </List>
            </Collapse>}

          </ListItem>}
          {(accessRights === 'SYSTEM') && <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              id="nav-10"
              selected={selectedIndex === 10}
              onClick={() => {
                sessionStorage.setItem('selectedIndex', 10)
                setSelectedIndex(10)
                setSystemToggle(!systemToggle)
                props.setupStructureTrigger()
                setAdminToggle(false)
                setOpenSetupToggle(false)
              }}

              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,

              }
              }
            >
              <Tooltip title={window.gt('Systems')} placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <LanguageIcon className={props.open ? 'no-apply-btn' : 'apply-btn'} />
              </ListItemIcon></Tooltip>
              <ListItemText primary={window.gt('Systems')} sx={{ opacity: props.open ? 1 : 0 }} />
              {props.open && <span style={{position: 'relative', top: '2px'}}>{systemToggle ? <ExpandLess /> : <ExpandMore />}</span>}
            </ListItemButton>
            {props.open && <Collapse in={systemToggle} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 8.7 }} id="nav-ch-1" className='child-selected' onClick={() => {
                  setChildIndexSetup(15)
                  sessionStorage.setItem('selectedIndex', 10)
                  setSelectedIndex(10)
                  setSystemToggle(true)
                  props.setupStructureTrigger()
                  props.navigateAdminSysLoookups()
                }} selected={childIndexSetup === 15}>
                  <ListItemText primary={window.gt('Lookups')} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 8.7 }} id="nav-ch-2" className='child-selected' onClick={() => {
                  setChildIndexSetup(16)
                  sessionStorage.setItem('selectedIndex', 10)
                  setSelectedIndex(10)
                  setSystemToggle(true)
                  props.setupStructureTrigger()
                  props.navigateLanguages()
                }} selected={childIndexSetup === 16}>
                  <ListItemText primary={window.gt('Languages')} />
                </ListItemButton>
                <ListItemButton sx={{ pl: 8.7 }} id="nav-ch-2" className='child-selected' onClick={() => {
                  setChildIndexSetup(16)
                  sessionStorage.setItem('selectedIndex', 11)
                  setSelectedIndex(10)
                  props.navigateLanguages()
                }} selected={childIndexSetup === 17}>
                  <ListItemText primary={window.gt('Refresh Cache')} />
                </ListItemButton>
              </List>
            </Collapse>}
          </ListItem>}
        </List>
      </Drawer>}
    </>
  );
}
