import dayjs from "dayjs";

const Constants = {
    Api:{
        commonUrl:(window.location.host.includes('localhost') || window.location.host.includes('profiledev')) ? 'https://services.clarity4d.com/C4DProfile/c4dapi.asmx/' : 'https://services.clarity4d.com/C4DProfile2/c4dapi.asmx/'
        // commonUrl:'https://services.clarity4d.com/C4DProfile2/c4dapi.asmx/'
    },
    dashboardJson : {
        dashboardId : 'HOME',
        period : dayjs().format('DD/MM/YYYY')
    },
    profileStatusJson : {
        statusPeriod : '6MTH'
    },
    loginContainer : {
        formLabel : {
            usernameLabel : 'User name',
            passwordLabel : 'Password',
            buttonLabel : 'Login',
            versionLabel : 'Version'
        }
    },
    emailJS:{
        serviceName: 'service_4hfz3rm',
        key: 'Ha6CtAyRG7PFMtJOQ',
        templateName: 'template_pwkdsgb'
    },
    helpWindow: {
        name: '_blank',
        height: '600',
        width: '600'
    },
    MenuProps: {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
            },
        },
    },
    captcha_excluded_users: {
        '21108' : 21108,
        '21482' : 21482,
        '21484' : 21484,
        '21494' : 21494,
    }
}

export default Constants;

// fireni2206@gpipes.com
// Raw123!@#